import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>LSI Archi Consulting Limited</p>
        <ul className="Summary" >
        <p>Specialising in:</p>
          <li className="Summary" >AWS</li>
          <li>Large Scale Integration</li>
          <li>Consultancy Services</li>
          <li>Developement of IoT Solutions</li>
        </ul>
        <p>Currently engaged in a number of R&D projects</p>
        <p>Clients include:</p>
        <ul className="Summary" >
          <li>Airbus</li>
          <li>British Airways</li>
          <li>DVLA</li>
          <li>DWP</li>
          <li>Fujitsu</li>
          <li>HSBC</li>
          <li>NATS</li>
          <li>Rolls-Royce</li>
          <li>Swinton Insurance</li>
        </ul>
      </header>
    </div>
  );
}

export default App;
